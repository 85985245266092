<template>
  <div>
    <span class="group-titles">
      <router-link to="/clientes" title="Voltar"
        ><i class="mdi mdi-arrow-left-bold-circle-outline"></i>
      </router-link>
      <p class="title" v-show="store.clienteSelected">
        {{ store.clienteSelected.nomeFantasia }} -
        {{ Validators.formataCpfCnpj(store.clienteSelected.cnpjCpf) }}
      </p>
    </span>

    <DxAccordion
      ref="accordion"
      :collapsible="true"
      :data-source="state.items"
      item-template="item"
      item-title-template="title-accordion"
    >
      <template #title-accordion>Filtrar Movimento</template>
      <template #item>
        <form @submit.prevent="Filter">
          <DxForm
            :form-data="state.filtro"
            :show-colon-after-label="true"
            col-count="auto"
            :min-col-width="100"
            :col-count-by-screen="{ lg: 6, md: 6, sm: 1, xs: 1 }"
          >
            <DxSimpleItem
              data-field="Mes"
              :label="{ text: 'Mês' }"
              editor-type="dxSelectBox"
              :editor-options="{
                items: meses,
                displayExpr: 'name',
                valueExpr: 'id',
                value: state.filtro.Mes,
              }"
            />
            <DxSimpleItem
              data-field="Ano"
              editor-type="dxSelectBox"
              :editor-options="{
                items: state.anos,
                value: state.filtro.Ano,
              }"
            />
            <DxSimpleItem
              data-field="Modelo"
              editor-type="dxSelectBox"
              :editor-options="{
                items: modelo,
                displayExpr: 'name',
                valueExpr: 'id',
                value: state.filtro.Modelo,
                onValueChanged: SetModeloDocumento,
              }"
            />
            <DxSimpleItem
              data-field="Tipo"
              editor-type="dxSelectBox"
              :editor-options="{
                items: tipo,
                displayExpr: 'name',
                valueExpr: 'id',
                value: state.filtro.Tipo,
                readOnly: state.isReadOnly,
              }"
            />
            <DxButtonItem
              :button-options="filterButtonProperties"
              vertical-alignment="center"
            />
            <DxButtonItem
              :button-options="downloadButtonProperties"
              css-class="lg:mr-8"
              vertical-alignment="center"
            />
          </DxForm>
        </form>
      </template>
    </DxAccordion>

    <DxTabPanel
      height="100%"
      :show-nav-buttons="true"
      :repaint-changes-only="true"
      :animation-enabled="true"
    >
    <DxItem title="Documentos Fiscais">
      <template #default>
        <DocumentosFiscais/>
      </template>
    </DxItem>
    <DxItem title="ICMS">
      <template #default>
        <Icms/>
      </template>
    </DxItem>
    <DxItem title="ICMS ST">
      <template #default>
        <IcmsSt/>
      </template>
    </DxItem>
    <DxItem title="IPI">
      <template #default>
        <Ipi/>
      </template>
    </DxItem>
    <DxItem title="PIS/COFINS">
      <template #default>
        <PisCofins/>
      </template>
    </DxItem>
    <DxItem title="Produtos">
      <template #default>
        <Produtos/>
      </template>
    </DxItem>
    <DxItem title="Documentos Cancelados">
      <template #default>
        <DocumentosCancelados/>
      </template>
    </DxItem>
    <DxItem title="Documentos Inutilizados">
      <template #default>
        <DocumentosInutilizados/>
      </template>
    </DxItem>
    <DxItem title="MDF-e">
      <template #default>
        <Mdfe/>
      </template>
    </DxItem>
    <DxItem title="CT-e">
      <template #default>
        <Cte/>
      </template>
    </DxItem>
    <DxItem title="Arquivos Gerais">
      <template #default>
        <ArquivosGerais/>
      </template>
    </DxItem>
    </DxTabPanel>

    <DxLoadPanel
      :visible="state.isLoading"
      :show-pane="true"
      :shading="true"
      shading-color="rgba(255,255,255, 0.8)"
    />
  </div>
</template>

<script lang="ts" src="./index.ts"></script>

<style lang="postcss" scoped>
.group-titles {
  @apply flex items-baseline;
}
.title {
  @apply text-2xl ml-3 capitalize;
}
.component-tab {
  @apply mt-1;
}
.dx-accordion-item-title {
  @apply bg-gray-300;
}
</style>