import { defineAsyncComponent, onActivated } from 'vue';
import { documentoStore } from './../../store/modules/documentos/index';
import { IFiltroMovimentoRequest } from './../../models/IFiltroMovimentoRequest';
import { clienteStore } from '../../store/modules/clientes';
import { defineComponent, onMounted, reactive, ref } from 'vue';
import { DxAccordion } from 'devextreme-vue';
import { DxForm, DxSimpleItem, DxButtonItem, DxButtonOptions, DxGroupItem } from 'devextreme-vue/ui/form';
import { DxTabPanel, DxItem } from "devextreme-vue/ui/tab-panel";
import { meses, modelo, tipo } from '../../helpers/data-filter.json'
import { DxLoadPanel } from 'devextreme-vue/ui/load-panel';
import Validators from '../../helpers/validators-input'; 
import { portalContadorBaseUrl } from '@/helpers/constans';

export default defineComponent({
  name: 'Movimentações',
  setup() {
    onMounted(() => {
      if (sessionStorage.getItem('client_selected')) {
        const clientSessionStorage: any = sessionStorage.getItem('client_selected')
        store.clienteSelected = JSON.parse(clientSessionStorage)
      }
    })    
    
    onActivated(() => { 
      docStore.$reset()
      state.filtro.Mes = new Date().getMonth() + 1;
      state.filtro.Ano = new Date().getFullYear();
      state.filtro.Modelo = 0;
      state.filtro.Tipo = 2;
    })

    const store = clienteStore()
    const docStore = documentoStore()
    const accordion = ref()
    const state = reactive({
      filtro: {
        Mes: new Date().getMonth() + 1,
        Ano: new Date().getFullYear(),
        Modelo: 0,
        Tipo: 2
      } as IFiltroMovimentoRequest,
      anos: Validators.rangeAno(new Date().getFullYear(), new Date().getFullYear() - 10, -1),
      isReadOnly: false,
      items: [{ title: 'FILTRAR MOVIMENTO:' }],
      isLoading: false,
    })

    const filterButtonProperties = {
      text: ' Filtrar',
      type: 'default',
      stylingMode: 'contained',
      icon: 'mdi mdi-filter',
      useSubmitBehavior: true
    }

    const downloadButtonProperties = {
      text: ' Download',
      type: 'normal',
      stylingMode: 'contained',
      icon: 'mdi mdi-cloud-download',
      onClick: () => Download()
    }

    async function SetModeloDocumento(e: any) {
      const selectedModelo = e.value
      if (selectedModelo === 59 || selectedModelo === 65) {
        state.filtro.Tipo = 1
        state.filtro.Modelo = selectedModelo
        state.isReadOnly = true
      } else {
        state.filtro.Tipo = 2
        state.isReadOnly = false
      }
    }

    async function Filter() {
      docStore.$reset()
      state.isLoading = true;
      try {
        docStore.filter = state.filtro
        if (state.filtro.Modelo === 58) {
          await docStore.DOC_MDFE()
        } else {
          await docStore.DOC_ICMS()
          await docStore.DOC_ICMS_ST()
          await docStore.DOC_IPI()
          await docStore.DOC_PIS_COFINS()
          await docStore.DOC_PRODUTOS()
          await docStore.DOC_CFOP()
          await docStore.DOC_CST_ICMS()
          await docStore.DOC_CST_IPI()
          await docStore.DOC_CST_PIS_COFINS()
          await docStore.DOC_CANCELADOS()
          await docStore.DOC_INUTILIZADOS()
          await docStore.DOC_NCM_CST_ICMS()
          await docStore.DOC_NCM_CST_IPI()
          await docStore.DOC_NCM_CST_PIS_COFINS()
          await docStore.DOC_ARQUIVOS_GERAIS()
          await docStore.DOC_MDFE()
          await docStore.DOC_FISCAIS()
          await docStore.DOC_CTE()
          // await docStore.DOC_FALTANTES()
        }
        state.isLoading = false
      } catch (error) {
        return error
      }
      accordion.value.instance.collapseItem(0)
    }

    async function Download() {
      docStore.$reset();
      state.isLoading = true;
      docStore.filter = state.filtro;
      const response = await docStore.GET_DOWNLOAD_DOCUMENTOS();
      if (response) {   
        const archive = portalContadorBaseUrl+response;
        state.isLoading = false;
        window.open(archive);
      }
      state.isLoading = false
    }

    return {
      store,
      meses,
      modelo,
      tipo,
      state,
      filterButtonProperties,
      downloadButtonProperties,
      Validators,
      accordion,
      SetModeloDocumento,
      Filter,
      Download
    }
  },
  components: {
    DxAccordion,
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxButtonOptions,
    DxGroupItem,
    DxTabPanel,
    DxLoadPanel,
    DxItem,
    DocumentosFiscais: defineAsyncComponent(() => import('../../components/tabs/documentos-fiscais/index.vue')),
    Icms: defineAsyncComponent(() => import('../../components/tabs/icms/index.vue')),
    IcmsSt: defineAsyncComponent(() => import('../../components/tabs/icms-st/index.vue')),
    Ipi: defineAsyncComponent(() => import('../../components/tabs/ipi/index.vue')),
    PisCofins: defineAsyncComponent(() => import('../../components/tabs/pis-cofins/index.vue')),
    Produtos: defineAsyncComponent(() => import('../../components/tabs/produtos/index.vue')),
    DocumentosCancelados: defineAsyncComponent(() => import('../../components/tabs/documentos-cancelados/index.vue')),
    DocumentosInutilizados: defineAsyncComponent(() => import('../../components/tabs/documentos-inutilizados/index.vue')),
    Mdfe: defineAsyncComponent(() => import('../../components/tabs/mdf-e/index.vue')),
    ArquivosGerais: defineAsyncComponent(() => import('../../components/tabs/arquivos-gerais/index.vue')),
    Cte: defineAsyncComponent(() => import('../../components/tabs/cte/index.vue'))
  }
})